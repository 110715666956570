import React from 'react'
import { Button } from 'react-bootstrap'

const SummerSwimPartyContent = (
    <>
        <p>Our 2022 Summer Swim Party will be held on August 18th from 6-8 pm at Rexburg Rapids! Register below to sign your whole family up!</p>
        <div style={{textAlign: "center"}}>
            <a href="https://tetonvalleyaquatics.app.neoncrm.com/eventReg.jsp?event=461&" target="_blank" rel="noopener noreferrer"> 
                <Button>
                    Register Now
                </Button>
            </a>
        </div>
    </>
)

export default SummerSwimPartyContent;