import React from "react"
import Img from "gatsby-image"
import ProgramModal from "./ProgramModal"

import styles from "./ProgramsEvents.module.css"

function Modal(props) {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
    <div style={{ width: "100%", height: "100%", position: "relative", cursor: "pointer" }}
    onClick={() => setModalShow(true)} role="button"
    >
        <Img
            fluid={props.fluid}
            alt={props.alt}
            className={props.className}
        />
        <div className={`${styles.gridImageContainer} ${styles.joshTheOtterTitle}`}>
                <p className={styles.imageLinkText}>
                    {props.imageLinkText}
                </p>
              </div>
        </div>       
      <ProgramModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalTitle={props.modalTitle}
        modalContent={props.modalContent}
      />
    </>
  )
}

export default Modal