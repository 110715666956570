import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import SEO from "../components/seo"

import ProgramsEventsAboutBlock from "../components/ProgramsEvents/ProgramsEventsAboutBlock"
import HomeNewsletterBlock from "../components/HomeNewsletterBlock/HomeNewsletterBlock"
import ProgramsBlock from "../components/ProgramsEvents/ProgramsBlock"
import AnnouncementModal from "../components/AnnouncementModal/AnnoucementModal"

function Modal() {
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    //if (!sessionStorage.popupModal) {
    const modalTimer = setTimeout(() => {
      setModalShow(true)
      //sessionStorage.popupModal = 1
    }, 1000)
    return () => {
      clearTimeout(modalTimer)
    }
    //}
  }, [])

  return (
    <>
      <AnnouncementModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalBody={
          <>
            <p>Applications for 2021 Swim Lessons have closed, but we may still have some availability. Please email <a href="mailto:info@tetonvalleyaquatics.org">info@tetonvalleyaquatics.org</a> to inquire.</p>
          </>
        }
      />
    </>
  )
}

const ProgramsEventsPage = () => (
  <>
    <Layout>
      <SEO title="Teton Valley Aquatics Programs &amp; Events" />
      {/* <Modal /> */}
      <Container fluid>
        <Container>
          <ProgramsBlock />
        </Container>
        <Container>
          <ProgramsEventsAboutBlock />
        </Container>
        <HomeNewsletterBlock />
      </Container>
    </Layout>
  </>
)

export default ProgramsEventsPage
