import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const WinterSportsContent = () => {
    
    const data = useStaticQuery(graphql`
    query {
      summerSwimSchedule: file(relativePath: { eq: "2022-Summer-Pool-Schedule.png" }) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
    )
    return (
    <div>
        <Img fluid={data.summerSwimSchedule.childImageSharp.fluid} alt="Summer 2022 Swim Schedule" />
    </div>
)
}

export default WinterSportsContent;