import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { Button, Row, Col } from 'react-bootstrap'
import styles from "../../HomeModal/homeModal.module.css"

const SwimLessonsContent = (
    <>
        <Row className="mb-2 mt-4">
                  <Col>
                  <Link to="/summer-swim-lessons" className="btn-link">
                  <Button className={`btn-block ${styles.letterButton}`}>
                  Summer Swim Lesson Information <FontAwesomeIcon icon={faAngleRight} aria-hidden="true" /></Button></Link>
                  </Col>
                </Row>
                <Row className="mb-2 mt-4">
                  <Col>
                  <Link to="/summer-swim-lessons-espanol" className="btn-link">
                  <Button className={`btn-block ${styles.letterButton}`}>
                  Clases de natación en verano <FontAwesomeIcon icon={faAngleRight} aria-hidden="true" /></Button></Link>
                  </Col>
                </Row>
    </>
)

export default SwimLessonsContent;