import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"

function GivingTuesdayModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title style={{textAlign: 'center'}}>
                    {props.modalTitle}
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign: 'center'}}>
                {props.modalBody}
            
            </Modal.Body>
        </Modal>
    )
}

export default GivingTuesdayModal;