import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import styles from "./HomeNewsletterBlock.module.css"
import Modal from '../NewsletterModal/ModalButton'

// function Modal() {
//     const [modalShow, setModalShow] = React.useState(false)
  
//     return (
//       <>
//          <Button className={styles.newsletterButton}
//             onClick={() => setModalShow(true)}
//          >Sign Up</Button>

//          <NewsletterModal show={modalShow} onHide={() => setModalShow(false)} />
//       </>
//     )
//   }

const HomeNewsletterBlock = () => {
    return (
        <Container fluid className={styles.greenBox}>
            <Container style={{
                height: '100%'
            }}>
                <Row className="align-items-center" style={{
                    height: '100%',
                }}>
                    <Col sm={9}>
                    <h1 className={styles.headline}>Sign up for newsletters and updates</h1>
                    <p>Get the latest news, events, &amp; more from TVA</p>
                    </Col>
                    <Col sm={3}>
                        <Modal
                            signUpText="Stay In The Loop"
                            style={styles.newsletterButton}
                        />
                        {/* <Button className={styles.newsletterButton}>Sign Up</Button> */}
                    </Col>
                </Row>
            
            </Container>
            
        </Container>
    )
}

export default HomeNewsletterBlock;