import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"

import styles from "./ProgramsEvents.module.css"
import SwimmerIcon from "../../images/programs/SwimmerIcon"
import DropletIcon from "../../images/programs/DropletIcon"
import WaterIcon from "../../images/programs/WaterIcon"

const ProgramsEventsAboutBlock = () => {
  const data = useStaticQuery(graphql`
    query {
      kidsSwimming: file(
        relativePath: { eq: "programs/kids-swimming-closeup.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lifeJackets: file(
        relativePath: { eq: "programs/life-jackets-tin-cup.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      {/* <Row className={`my-4 ${styles.centerVertical}`}>
        <Col sm={6}>
          <Img
            fluid={data.kidsSwimming.childImageSharp.fluid}
            alt="Kids having fun while swimming"
            className="rounded my-4"
          />
        </Col>
        <Col sm={6}>
          <h1 className={styles.blockHeadline}>Swimming is more than exercise… it saves lives</h1>
          <p className={styles.blockCopy}>
          Teton Valley Aquatics strives to educate all ages about the benefits of swimming for longterm health.
          </p>
          <div>
            <div className={styles.blockList}>
              <div style={{
                marginRight: '15px'
              }}
              >
            <SwimmerIcon style={{
              width: '40px'
            }}/>
              </div>
              <div>
                <p className={styles.listCopy}>
                Research has shown that participation in formal swimming lessons can reduce the risk of drowning among children aged 1 to 4 years.
                </p>
              </div>
            </div>
            <div className={styles.blockList}>
              <div style={{
                marginRight: '15px'
              }}
              >
            <WaterIcon style={{
              width: '40px'
            }}/>
              </div>
              <div>
                <p className={styles.listCopy}>
                Drowning is responsible for more deaths in children ages 1-4 than any other cause except birth defects.
                </p>
              </div>
            </div>
            <div className={styles.blockList}>
              <div style={{
                marginRight: '15px'
              }}
              >
            <DropletIcon style={{
              width: '40px'
            }}/>
              </div>
              <div>
                <p className={styles.listCopy}>
                Minorities and individuals of lower socio-economic status drown at greater rates than white, with greatest disparity between ages 5-18.
                </p>
              </div>
            </div>
            </div>
        </Col>
      </Row> */}

      <Row className={`my-4 ${styles.centerVertical}`}>
        <Col sm={6}>
          <h1 className={styles.blockHeadline}>Tin Cup Challenge</h1>
          <p className={styles.blockCopy}>
          Thanks to the Community Foundation and the Tin Cup Challenge, we are able to raise much needed funds for operating expenses, which is extremely important as we take on the next stage of our project now that a site has been established.</p>
          {/* <Row className="text-center">
            <Col sm={6}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="#558C8F"
                size="4x"
                className={styles.greenCheck}
              />
              <p className={styles.greenCheckTitle}>Long Term Health</p>
            </Col>
            <Col sm={6}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="#558C8F"
                size="4x"
                className={styles.greenCheck}
              />
              <p className={styles.greenCheckTitle}>Water Safety</p>
            </Col>
          </Row>
          <Row className="text-center">
            <Col sm={6}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="#558C8F"
                size="4x"
                className={styles.greenCheck}
              />
              <p className={styles.greenCheckTitle}>Stress Relief</p>
            </Col>
            <Col sm={6}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="#558C8F"
                size="4x"
                className={styles.greenCheck}
              />
              <p className={styles.greenCheckTitle}>Population Health</p>
            </Col>
          </Row> */}
        </Col>
        <Col sm={6}>
          <Img
            fluid={data.lifeJackets.childImageSharp.fluid}
            alt="Kids having fun while swimming"
            className="rounded my-4"
          />
        </Col>
      </Row>
      
    </Container>
  )
}

export default ProgramsEventsAboutBlock
