import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import ProgramModalLink from "./ProgramModalLink"

import JoshTheOtterContent from "./ModalContent/JoshTheOtterContent"
import WinterSportsContent from "./ModalContent/WinterSportsContent"
import TinCupChallenge from "./ModalContent/TinCupChallenge"
import SwimLessonsContent from "./ModalContent/SwimLessonsContent"
import LifeJacketsContent from "./ModalContent/LifeJacketsContent"
import SummerSwimPartyContent from "./ModalContent/SummerSwimPartyContent"
import SummerPoolActivities from "./ModalContent/SummerPoolActivities"

import styles from "./ProgramsEvents.module.css"


const ProgramsBlock = () => {
  const data = useStaticQuery(graphql`
    query {
      joshTheOtter: file(relativePath: { eq: "programs/josh-the-otter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      summerPool: file(relativePath: { eq: "summer-pool.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tinCupChallenge: file(
        relativePath: { eq: "programs/tin-cup-challenge.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      winterSportsSquare: file(relativePath: { eq: "programs/winter-sports-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tinCupChallengeSquare: file(
        relativePath: { eq: "programs/tin-cup-challenge-square.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      swimLessons: file(relativePath: { eq: "programs/swim-lessons.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lifeJackets: file(
        relativePath: { eq: "programs/life-jacket-loaner-stations.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      summerSwimParty: file(relativePath: { eq: "programs/summer-swim-lessons.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      summerSwimPartySquare: file(relativePath: { eq: "programs/summer-swim-lessons-square.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 982, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
    <Row className="my-4">
        <Col>
            <h1 className={`${styles.blockHeadline} text-center`}>Classes &amp; Programs</h1>
        </Col>
    </Row>
      <Container className="d-lg-none">
        <Row>
          <Col md={4} className="my-4">
          <div className={`${styles.joshTheOtterMobile}`}>
          <ProgramModalLink 
            fluid={data.joshTheOtter.childImageSharp.fluid}
            alt="Josh The Otter"
            className={styles.gridImage}
            title="Josh The Otter"
            imageLinkText="Josh The Otter"
            modalTitle="Josh The Otter"
            modalContent={JoshTheOtterContent}
          />
          </div>
          </Col>
          <Col md={4} className="my-4">
          <div className={`${styles.winterSportsMobile}`}>
            <ProgramModalLink 
                fluid={data.summerPool.childImageSharp.fluid}
                alt="Winter Sports"
                className={styles.gridImage}
                title="Summer Pool Schedule"
                imageLinkText="Summer Pool Schedule"
                modalTitle="Summer Pool Schedule"
                modalContent={<WinterSportsContent />}
            />
          </div>
          </Col>
          <Col md={4} className="my-4">
          <div className={`${styles.tinCupChallengeMobile}`}>
          <ProgramModalLink 
                fluid={data.tinCupChallengeSquare.childImageSharp.fluid}
                alt="Summer Adult Pool Activities"
                className={styles.gridImage}
                title="Summer Adult Pool Activities"
                imageLinkText="Summer Adult Pool Activities"
                modalTitle="Summer Adult Pool Activities"
                modalContent={SummerPoolActivities}
            />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="my-2">
          <div className={`${styles.swimLessons}`}>
          <ProgramModalLink 
                fluid={data.swimLessons.childImageSharp.fluid}
                alt="Swim Lessons"
                className={styles.gridImage}
                title="Swim Lessons"
                imageLinkText="Swim Lessons"
                modalTitle="Swim Lessons"
                modalContent={SwimLessonsContent}
            />
          </div>
          </Col>
          <Col md={4} className="my-2">
          <div className={`${styles.lifeJackets}`}>
          <ProgramModalLink 
                fluid={data.lifeJackets.childImageSharp.fluid}
                alt="Life Jacket Loaner Stations"
                className={styles.gridImage}
                title="Life Jacket Loaner Stations"
                imageLinkText="Life Jacket Loaner Stations"
                modalTitle="Life Jacket Loaner Stations"
                modalContent={LifeJacketsContent}
            />
            </div>
          </Col>
          <Col md={4} className="my-2">
          <div className={`${styles.summerSwimPartySquare}`}>
          <ProgramModalLink 
                fluid={data.summerSwimPartySquare.childImageSharp.fluid}
                alt="Summer Swim Party"
                className={styles.gridImage}
                title="Summer Swim Party"
                imageLinkText="Summer Swim Party"
                modalTitle="Summer Swim Party"
                modalContent={SummerSwimPartyContent}
            />
          </div>
          </Col>
        </Row>
        
      </Container>
      <Container className="my-4 d-none d-lg-block">
        <div className={styles.programBox}>
          <div className={`${styles.joshTheOtter} cell`}>
          <ProgramModalLink 
            fluid={data.joshTheOtter.childImageSharp.fluid}
            alt="Josh The Otter"
            className={styles.gridImage}
            title="Josh The Otter"
            imageLinkText="Josh The Otter"
            modalTitle="Josh The Otter"
            modalContent={JoshTheOtterContent}
          />
          </div>
          <div className={`${styles.winterSports} cell`}>
            <ProgramModalLink 
                fluid={data.summerPool.childImageSharp.fluid}
                alt="Winter Sports"
                className={styles.gridImage}
                title="Summer Pool Schedule"
                imageLinkText="Summer Pool Schedule"
                modalTitle="Summer Pool Schedule"
                modalContent={<WinterSportsContent />}
            />
          </div>
          
          <div className={`${styles.tinCupChallenge} cell`}>
          <ProgramModalLink 
                fluid={data.tinCupChallenge.childImageSharp.fluid}
                alt="Summer Adult Pool Activities"
                className={styles.gridImage}
                title="Summer Adult Pool Activities"
                imageLinkText="Summer Adult Pool Activities"
                modalTitle="Summer Adult Pool Activities"
                modalContent={SummerPoolActivities}
            />
            </div>
          <div className={`${styles.swimLessons} cell`}>
          <ProgramModalLink 
                fluid={data.swimLessons.childImageSharp.fluid}
                alt="Swim Lessons"
                className={styles.gridImage}
                title="Swim Lessons"
                imageLinkText="Swim Lessons"
                modalTitle="Swim Lessons"
                modalContent={SwimLessonsContent}
            />
          </div>
          <div className={`${styles.lifeJackets} cell`}>
          <ProgramModalLink 
                fluid={data.lifeJackets.childImageSharp.fluid}
                alt="Life Jacket Loaner Stations"
                className={styles.gridImage}
                title="Life Jacket Loaner Stations"
                imageLinkText="Life Jacket Loaner Stations"
                modalTitle="Life Jacket Loaner Stations"
                modalContent={LifeJacketsContent}
            />
            </div>
          <div className={`${styles.summerSwimParty} cell`}>
          <ProgramModalLink 
                fluid={data.summerSwimParty.childImageSharp.fluid}
                alt="Summer Swim Party"
                className={styles.gridImage}
                title="Summer Swim Party"
                imageLinkText="Summer Swim Party"
                modalTitle="Summer Swim Party"
                modalContent={SummerSwimPartyContent}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default ProgramsBlock
